import React from 'react'
import {useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

  import Upcoming from "./Upcoming"
  import App from "./App"
  import Ranking from "./Ranking"
  import Faq from "./Faq"
  import Submit from './Submit'
  import Topbar from "./Topbar"
  import Footer from "./Footer"
  import Submitcollection from './Submitcollection.js'
  import Admin from "./Admin"
  import Login from "./Login"
  import Register from './Register'
  import Blog from './Blog'
  import Blogarticles from "./Blogarticles"
  import Auth from './Auth'


function Routes() {

    const [user,setLoginUser] = useState({

    })


    return (
        <div>
            <Router>
            <Topbar />

            
                <Switch>
                        <Route exact path="/" >
                            <App />
                        </Route>
                        <Route exact path="/upcoming">
                            <Upcoming />
                        </Route> 
                        <Route exact path="/ranking">
                            <Ranking />
                        </Route>
                        <Route exact path="/contact">
                            <Submit />
                        </Route>
                        <Route exact path="/submit">
                            <Submitcollection />
                        </Route>
                        <Route exact path="/faq">
                            <Faq />
                        </Route>
                        
                        <Route exact path="/admin">
                            <Admin />
                        </Route>

                        <Route exact path="/blog">
                            <Blog />
                        </Route>

                        <Route exact path="/blog/:id" component={Blogarticles}>
                            
                        </Route>

                        <Route exact path="/login">
                            <Auth />
                        </Route>
                        


                </Switch>
            

            <Footer/>
            </Router>

      

            
        </div>
    )
}

export default Routes
