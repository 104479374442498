import React from 'react'
import './Featuredcard.css'
import moment from 'moment';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Icon } from '@iconify/react';
import LanguageIcon from '@mui/icons-material/Language';
import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import Trend from 'react-trend';
import Dropscoresvg from "./img/dropscorewhite.png";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { LinearProgress } from '@mui/material';
import { Sparklines, SparklinesSpots, SparklinesLine, SparklinesBars } from 'react-sparklines';
import SolanaImage from './img/solana.svg'
import EthereumImage from './img/ethereum.svg'
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import MediaQuery from 'react-responsive';

function Featuredcard(props) {


    const arrayofstats = props.tweetsmetrics ? props.tweetsmetrics : 0
    const labeldata = arrayofstats ? arrayofstats.map(a => moment(new Date(a.created_at)).format("DD/MM/YYYY")) : 0

    const labeldata1 = labeldata ? labeldata.reverse() : 0

    
    const datasetdata = arrayofstats === 0 ? 0 :  arrayofstats.map(a => a.public_metrics.like_count) 

    const datasetdata1 = datasetdata ? datasetdata.reverse() : 0



    const datasetdatart = arrayofstats ? arrayofstats.map(a => a.public_metrics.retweet_count) : 0

    const datasetdatart1 = datasetdatart ? datasetdatart.reverse() : 0



    const datasetdatareply = arrayofstats ? arrayofstats.map(a => a.public_metrics.reply_count) : 0

    const datasetdatareply1 = datasetdatareply ? datasetdatareply.reverse() : 0



    const datasetdataquote = arrayofstats ? arrayofstats.map(a => a.public_metrics.quote_count) : 0

    const datasetdataquote1 =  datasetdataquote ? datasetdataquote.reverse() : 0


    const totalengagement = arrayofstats ? arrayofstats.map(a => a.public_metrics.quote_count + a.public_metrics.reply_count + a.public_metrics.retweet_count + a.public_metrics.like_count) : 0

    const totalengagement1 = totalengagement ? totalengagement.reverse() : 0
   


   

    const options={
        plugins: {
            legend: {
                display: false,
                
                
            },
            
        },
        scales: {
            xAxis: {
                display: true,
                grid: {
                    display:false
                }
            },
            yAxis: {
                display: true,
                grid: {
                    display:true
                }
            }

        },
        elements: {
            point:{
                radius: 0
            }
        },
        interaction: {
            mode: 'x'
        },
        
        
        
        maintainAspectRatio: false,
       
    };


    const description = props.description ? props.description.substring(0, 50) + "..." : 0;


    const numberofitem = props.socialmetrics ? props.socialmetrics.length : 0;

    const twitterfollowersgrowth5 = numberofitem === 0 ? 0 : props.socialmetrics ? props.socialmetrics[numberofitem-1].nbtwt : 0;
   const twitterfollowersgrowth4 = numberofitem <2 ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-2].nbtwt : 0;
   const twitterfollowersgrowth3 = numberofitem <3 ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-3].nbtwt : 0;
   const twitterfollowersgrowth2 = numberofitem <4 ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-4].nbtwt : 0;
   const twitterfollowersgrowth1 = numberofitem <5 ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-5].nbtwt : 0;
   


    const TwitterChartData = [twitterfollowersgrowth2,twitterfollowersgrowth3,twitterfollowersgrowth4,twitterfollowersgrowth5]
 
 
    const discordfollowersgrowth5 = numberofitem === 0 ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-1].nbdscrd : 0;
    const discordfollowersgrowth4 = numberofitem <2 ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-2].nbdscrd : 0;
    const discordfollowersgrowth3 = numberofitem <3 ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-3].nbdscrd : 0;
    const discordfollowersgrowth2 = numberofitem <4  ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-4].nbdscrd : 0;
    const discordfollowersgrowth1 = numberofitem <5 ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-5].nbdscrd : 0;
 
 
    const DiscordChartData = [discordfollowersgrowth2,discordfollowersgrowth3,discordfollowersgrowth4,discordfollowersgrowth5]
 
 
 
    const OnedaysgrowthTwitter =  parseInt(twitterfollowersgrowth5) - parseInt(twitterfollowersgrowth4)
    const OnedaysgrowthDiscord =  parseInt(discordfollowersgrowth5) - parseInt(discordfollowersgrowth4)
 
    const SevendaysgrowthTwitter =  parseInt(twitterfollowersgrowth5) - parseInt(twitterfollowersgrowth1)
    const SevendaysgrowthDiscord =  parseInt(discordfollowersgrowth5) - parseInt(discordfollowersgrowth1)
 
    
 
    const percent =  Math.round(props.dropscore / props.maxdropscore *100)
 
 
    const [show, setShow] = useState(false);
 
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
    
 
    const piechartdata = {
     percent: percent, // is require
     colorSlice: '#1bd88f',
     colorCircle: '#393752',
     fontColor: '#1bd88f',
     fontSize: '1.6rem',
     fontWeight: 400,
     size: 30,
     stroke: 10,
     strokeBottom: 5,
     opacity: 10,
     speed: 80,
     round: true,
     number: true,
     
   }
 
 
   const piechartdatamodal = {
     percent: percent, // is require
     colorSlice: '#2962ff',
     colorCircle: '#393752',
     fontColor: '#2962ff',
     fontSize: '1.6rem',
     fontWeight: 400,
     size: 80,
     stroke: 10,
     strokeBottom: 5,
     opacity: 10,
     speed: 80,
     round: true,
     number: true,
     
   }
 



    return (


        <div className="featured-card-main">







<div>

<Modal size="lg" show={show} onHide={handleClose} className="modal-90w" >
<MediaQuery minWidth={1000}>
        <Modal.Header  closeVariant="white">
            <p className="close-button" onClick={handleClose}>X</p>
        
        
        </Modal.Header >

        <div className="modal-header-container">

                <div className="modal-title-description-container">

                    <h1  className="modal-title-props">{props.name}</h1>
                    <div className="dropscore-blockchain-modal">
                    <p className="blockchain-text-modal">Dropscore: {props.dropscore}</p>
                    {props.blockchain === "Ethereum" ?( <div>
                    <p className="blockchain-text-modal"> <img  className="ethereum-modal" src={EthereumImage}/> Ethereum</p>
                    </div>)  :


                    (<div>
                    <p className="blockchain-text-modal"> <img  className="ethereum-modal" src={SolanaImage}/> Solana</p>
                    </div>)}
                    </div>

                    

                </div>

                <div className="icon-container-modal">

                    <div className="link-items">
                        <a  className="card-links"href={props.website}><LanguageIcon/></a>
                    </div>

                    <div className="link-items">
                     <a  className="card-links" href={'https://twitter.com/' + props.twitter}><TwitterIcon /></a>
                    </div>

                    <div className="link-items">
                     <a className="card-links" href={props.discord}><Icon   icon="ph:discord-logo-fill" style={{ fontSize: '24px' }} /></a>
                    </div>

                </div>


        </div>
        




            <div className="modal-header">

                
                <div > 
                    
                
                    <p className="modal-description">{props.description}</p>


                    <div className="modal-image-continer">

                        <div className="modal-image">
                    
                        <img className="projectimage-modal" src={props.image} />

                        </div>

                    

                        <div className="modal-dropscore-social">


                                <div className="price-modal">
                                    <p className="modal-price">Price: {props.mintprice}</p>

                                </div>

                            

                                    <div className="social-score-modal">

                                            <p className="modal-social-score">Social Score:  </p>
                                            <CircularProgressBar {...piechartdatamodal}/>

                                    </div>

                                     

                        </div>



                        <div className="modal-dropscore-social">


                        <div className="followers-stats-featured-card-modal">


                                    <div className="featured-twitter-followers">
                                        <a className="featured-twitter-count"  href={'https://twitter.com/' + props.twitter}><TwitterIcon style={{ fontSize: '18px' }}/>{props.numberoftwitterfollowers}</a>
                                    </div>

                                    <div className="sparking">
                                            <Sparklines data={TwitterChartData} limit={5} width={100} height={20} margin={5}>
                                            <SparklinesBars style={{ fill: "#41c3f9" }}/>
                                            </Sparklines>
                                    </div>

                                    <div className="sparking-twenty" >
                                         <p className="twentyhours-featuredcard">24 hours</p> 
                                            <p><span className={ OnedaysgrowthTwitter >= 0 ? 'td-priceChange24h-true-featuredcard':'td-priceChange24h-false-featuredcard' }>{ OnedaysgrowthTwitter > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthTwitter}</span> </p>

                                    </div>

                        </div>



                        <div className="followers-stats-featured-card-modal">


                                <div className="featured-discord-followers">
                                    <a className="featured-discord-count"  href={props.discord}><Icon   icon="ph:discord-logo-fill" style={{ fontSize: '18px' }} />{props.numberofdiscordmembers}</a>
                                </div>

                                <div className="sparking" >
                                
                                <Sparklines data={DiscordChartData} limit={5} width={100} height={20} margin={5}>
                                <SparklinesBars style={{ fill: "#41c3f9" }}/>
                                </Sparklines>
                                </div>

                                <div className="sparking-twenty" >
                                <p className="twentyhours-featuredcard">24 hours</p> 
                                <p><span className={ OnedaysgrowthDiscord >= 0 ? 'td-priceChange24h-true-featuredcard':'td-priceChange24h-false-featuredcard' }>{ OnedaysgrowthDiscord > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthDiscord}</span> </p>
                               
                                </div>

                        </div>

                            

                                   

                                     

                        </div>

                       

                    </div>

                        

                </div>
                

            </div>
                
    
                <Modal.Body >

                    <p className="modal-social-score">Release date: <span className="releasedate-modal">{props.release}</span></p>

               <div className="modal-stats-container">
                
                    <div className="Twitter-stats-modal">


                                <div className="changedata">
                                    
                                    <p className="twentyhours">24 hours / 7 days</p>
                                    <p><span className={ OnedaysgrowthTwitter >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ OnedaysgrowthTwitter > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthTwitter}</span> / <span className={ SevendaysgrowthTwitter >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ SevendaysgrowthTwitter > 0 ? <span>+</span>: <span></span> }{SevendaysgrowthTwitter}</span></p>
                                    <p><TwitterIcon fontSize="small"  color="primary"/> {twitterfollowersgrowth5}</p>
                                

                                </div>

                
                            

                                <div className="chart">
                                    <Trend autoDraw autoDrawDuration={3000} data={TwitterChartData} gradient={['#2657eb', '#E439CA', '#E439CA']} strokeWidth={5} radius={20} smooth />

                                

                                    
                                </div>

                    </div>



                <div className="Twitter-stats-modal">


                            <div className="changedata">
                                <p className="twentyhours">24 hours / 7 days</p> 
                                <p><span className={ OnedaysgrowthDiscord >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ OnedaysgrowthDiscord > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthDiscord}</span> / <span className={ SevendaysgrowthDiscord >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ SevendaysgrowthDiscord > 0 ? <span>+</span>: <span></span> }{SevendaysgrowthDiscord}</span></p>
                                <p><Icon icon="logos:discord-icon" />  {discordfollowersgrowth5}</p>
                            </div>
                                
                                
                    
                                

                                <div className="chart">

                                


                                <Trend autoDraw autoDrawDuration={3000} data={DiscordChartData} gradient={['#2657eb', '#E439CA', '#E439CA']} strokeWidth={5} radius={20} smooth />

                             
                                </div>


                </div>


                


                </div>

                <div className="modal-stats-container">
           
                        <div className="Twitter-stats-modal-chartjs">

                                        <div className="chart-chart-js">
                                        

                                        <div>
                                        <h4>Likes per post</h4>
                                        </div>

                                        

                                        <div>
                                        <Chart  options={options}
                                                    height={300}  type='bar' data={{
                                                    labels: labeldata ? labeldata: null,
                                                    datasets: [
                                                    {
                                                        id: 1,
                                                        backgroundColor: 'rgb(88, 64, 224)',
                                                        fill: true,
                                                        borderColor: '#2CBEBA',
                                                        label: 'Likes',
                                                        
                                                        data: datasetdata ? datasetdata : null,
                                                        yAxisID: 'yAxis',
                                                        xAxisID: 'xAxis'
                                                    },
                                                    
                                                    ],
                                                    }} 
                                            />
                                            </div>
                                    
                                        </div>

                        </div>

                        <div className="Twitter-stats-modal-chartjs ">

                                        <div className="chart-chart-js">
                                        

                                        <div>
                                        <h4>Retweets per post</h4>
                                        </div>

                                        

                                        <div>
                                        <Chart  options={options}
                                                    height={300}  type='bar' data={{
                                                    labels: labeldata ? labeldata : null,
                                                    
                                                    datasets: [
                                                    {
                                                        id: 1,
                                                        backgroundColor: 'rgb(88, 64, 224)',
                                                        fill: true,
                                                        borderColor: '#2CBEBA',
                                                        label: 'Retweets',
                                                        
                                                        data: datasetdatart ? datasetdatart : null,
                                                        yAxisID: 'yAxis',
                                                        xAxisID: 'xAxis'
                                                    },
                                                    
                                                    ],
                                                    }} 
                                            />
                                            </div>
                                    
                                        </div>

                        </div>

                </div>


                <div className="modal-stats-container">
                
                  
                        <div className="Twitter-stats-modal-chartjs-fullwidth">

                                        <div className="chart-chart-js">
                                        

                                        <div>
                                        <h4>Total engagement per post</h4>
                                        </div>

                                        

                                        <div>
                                        <Chart  options={options}
                                                    height={300}  type='line' data={{
                                                    labels: labeldata ? labeldata : null,
                                                    datasets: [
                                                    {
                                                        id: 1,
                                                        backgroundColor: 'rgba(88, 64, 224, 0.151)',
                                                        fill: true,
                                                        borderColor: 'rgb(88, 64, 224)',
                                                        label: 'Total engagement',
                                                        data: totalengagement ? totalengagement : null,
                                                        yAxisID: 'yAxis',
                                                        xAxisID: 'xAxis'
                                                    },
                                                    
                                                    ],
                                                    }} 
                                            />
                                            </div>
                                    
                                        </div>

                        </div>

                        


                


                </div>



                


               
                
                
                
                
                </Modal.Body>
                </MediaQuery>


                <MediaQuery maxWidth={1000}>




                <Modal.Header  closeVariant="white">
            <p className="close-button" onClick={handleClose}>X</p>
        
        
        </Modal.Header >

        <div className="modal-header-container">

                <div className="modal-title-description-container">

                    <h1  className="modal-title-props">{props.name}</h1>
                    <div className="dropscore-blockchain-modal">
                    <p className="blockchain-text-modal">Dropscore: {props.dropscore}</p>
                    {props.blockchain === "Ethereum" ?( <div>
                    <p className="blockchain-text-modal"> <img  className="ethereum-modal" src={EthereumImage}/> Ethereum</p>
                    </div>)  :


                    (<div>
                    <p className="blockchain-text-modal"> <img  className="ethereum-modal" src={SolanaImage}/> Solana</p>
                    </div>)}
                    </div>

                    

                </div>

                <div className="icon-container-modal">

                    <div className="link-items">
                        <a  className="card-links"href={props.website}><LanguageIcon/></a>
                    </div>

                    <div className="link-items">
                     <a  className="card-links" href={'https://twitter.com/' + props.twitter}><TwitterIcon /></a>
                    </div>

                    <div className="link-items">
                     <a className="card-links" href={props.discord}><Icon   icon="ph:discord-logo-fill" style={{ fontSize: '24px' }} /></a>
                    </div>

                </div>


        </div>
        




            <div className="modal-header">

                
                <div > 
                    
                
                    <p className="modal-description">{props.description}</p>


                    <div className="modal-image-continer-mobile">

                        <div className="modal-image-mobile">
                    
                        <img className="projectimage-modal-mobile" src={props.image} />

                        </div>

                    

                        <div className="modal-dropscore-social-mobile">


                                <div className="price-modal">
                                    <p className="modal-price">Price: {props.mintprice}</p>

                                </div>

                            

                                    <div className="social-score-modal">

                                            <p className="modal-social-score">Social Score:  </p>
                                            <CircularProgressBar {...piechartdatamodal}/>

                                    </div>

                                     

                        </div>



                    </div>

                        

                </div>
                

            </div>
                
    
                <Modal.Body >

                    <p className="modal-social-score">Release date: <span className="releasedate-modal">{props.release}</span></p>

               <div className="modal-stats-container">
                
                    <div className="Twitter-stats-modal-mobile">


                                <div className="changedata">
                                    
                                    <p className="twentyhours">24 hours / 7 days</p>
                                    <p><span className={ OnedaysgrowthTwitter >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ OnedaysgrowthTwitter > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthTwitter}</span> / <span className={ SevendaysgrowthTwitter >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ SevendaysgrowthTwitter > 0 ? <span>+</span>: <span></span> }{SevendaysgrowthTwitter}</span></p>
                                    <p><TwitterIcon fontSize="small"  color="primary"/> {twitterfollowersgrowth5}</p>
                                

                                </div>

                
                            

                                <div className="chart">
                                    <Trend autoDraw autoDrawDuration={3000} data={TwitterChartData} gradient={['#2657eb', '#E439CA', '#E439CA']} strokeWidth={5} radius={20} smooth />

                                

                                    
                                </div>

                    </div>



                <div className="Twitter-stats-modal-mobile">


                            <div className="changedata">
                                <p className="twentyhours">24 hours / 7 days</p> 
                                <p><span className={ OnedaysgrowthDiscord >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ OnedaysgrowthDiscord > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthDiscord}</span> / <span className={ SevendaysgrowthDiscord >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ SevendaysgrowthDiscord > 0 ? <span>+</span>: <span></span> }{SevendaysgrowthDiscord}</span></p>
                                <p><Icon icon="logos:discord-icon" />  {discordfollowersgrowth5}</p>
                            </div>
                                
                                
                    
                                

                                <div className="chart">

                                


                                <Trend autoDraw autoDrawDuration={3000} data={DiscordChartData} gradient={['#2657eb', '#E439CA', '#E439CA']} strokeWidth={5} radius={20} smooth />

                             
                                </div>


                </div>


                


                </div>

                <div className="modal-stats-container">
           
                        <div className="Twitter-stats-modal-chartjs-fullwidth-mobile">

                                        <div className="chart-chart-js">
                                        

                                        <div>
                                        <h4>Likes per post</h4>
                                        </div>

                                        

                                        <div>
                                        <Chart  options={options}
                                                    height={300}  type='bar' data={{
                                                    labels: labeldata ? labeldata : null,
                                                    datasets: [
                                                    {
                                                        id: 1,
                                                        backgroundColor: 'rgb(88, 64, 224)',
                                                        fill: true,
                                                        borderColor: '#2CBEBA',
                                                        label: 'Likes',
                                                        
                                                        data: datasetdata ? datasetdata : null,
                                                        yAxisID: 'yAxis',
                                                        xAxisID: 'xAxis'
                                                    },
                                                    
                                                    ],
                                                    }} 
                                            />
                                            </div>
                                    
                                        </div>

                        </div>

                        <div className="Twitter-stats-modal-chartjs-fullwidth ">

                                        <div className="chart-chart-js">
                                        

                                        <div>
                                        <h4>Retweets per post</h4>
                                        </div>

                                        

                                        <div>
                                        <Chart  options={options}
                                                    height={300}  type='bar' data={{
                                                    labels: labeldata ? labeldata : null,
                                                    
                                                    datasets: [
                                                    {
                                                        id: 1,
                                                        backgroundColor: 'rgb(88, 64, 224)',
                                                        fill: true,
                                                        borderColor: '#2CBEBA',
                                                        label: 'Retweets',
                                                        
                                                        data: datasetdatart ? datasetdatart : null,
                                                        yAxisID: 'yAxis',
                                                        xAxisID: 'xAxis'
                                                    },
                                                    
                                                    ],
                                                    }} 
                                            />
                                            </div>
                                    
                                        </div>

                        </div>

                </div>


                <div className="modal-stats-container">
                
                  
                        <div className="Twitter-stats-modal-chartjs-fullwidth">

                                        <div className="chart-chart-js">
                                        

                                        <div>
                                        <h4>Total engagement per post</h4>
                                        </div>

                                        

                                        <div>
                                        <Chart  options={options}
                                                    height={300}  type='line' data={{
                                                    labels: labeldata ? labeldata: null,
                                                    datasets: [
                                                    {
                                                        id: 1,
                                                        backgroundColor: 'rgba(88, 64, 224, 0.151)',
                                                        fill: true,
                                                        borderColor: 'rgb(88, 64, 224)',
                                                        label: 'Total engagement',
                                                        data: totalengagement ? totalengagement: null,
                                                        yAxisID: 'yAxis',
                                                        xAxisID: 'xAxis'
                                                    },
                                                    
                                                    ],
                                                    }} 
                                            />
                                            </div>
                                    
                                        </div>

                        </div>

                        


                


                </div>



                


               
                
                
                
                
                </Modal.Body>


                </MediaQuery>
</Modal>


</div>








        <div className="featured-card" onClick={handleShow}>







            <div className="solana-cart-container">
            
           
            </div>
            {props.isFeatured ? <p className="featured-sponsored-event">Featured  </p> : null}
            <div className="featured-first-container" >

                <div className='image-container-featuredcard'>
                    <img className="featured-projectimage" onError={(e) => {
                            e.target.src = 'https://bernardmarr.com/wp-content/uploads/2021/11/What-Are-NFTs-An-Easy-Explanation-For-Anyone.jpg' // some replacement image
                            e.target.style = 'padding: 8px; margin: 16px' // inline styles in html format
                        }} src={props.image} />

                    <div className="featured-icons">
                        <a  className="card-links-featuredcards"href={props.website}><LanguageIcon style={{ fontSize: '18px' }}/></a>
                        <a  className="card-links-featuredcards" href={'https://twitter.com/' + props.twitter}><TwitterIcon style={{ fontSize: '18px' }}/></a>
                        <a className="card-links-featuredcards" href={props.discord}><Icon   icon="ph:discord-logo-fill" style={{ fontSize: '18px' }} /></a>
                    </div>

                    {props.blockchain === "Ethereum" ?( <div>
                    <p className="blockchain-text"> <img  className="ethereum" src={EthereumImage}/> Ethereum</p>
                    </div>)  :


                    (<div>
                    <p className="blockchain-text"> <img  className="ethereum" src={SolanaImage}/> Solana</p>
                    </div>)}

                    <div className="featured-date-container">
                        <p className="featured-date">{props.release}</p>
                        
                    </div>
                    
                    
                    

                    
                    

                </div>
                


                <div>
                    <h1 className="featured-name">{props.name} </h1>


                    <div className="dropscore-featuredcard">
                            <p className="dropscore-featuredcard-score">Dropscore: {props.dropscore}</p>
                            
                    </div>

                    <p className="featured-descrption">{description}</p>

                    <div className="featured-followers-count-container">


                        <div className="followers-stats-featured-card">


                                <div className="featured-twitter-followers">
                                    <a className="featured-twitter-counts"  href={'https://twitter.com/' + props.twitter}><TwitterIcon style={{ fontSize: '18px' }}/>{props.numberoftwitterfollowers}</a>
                                </div>

                                <div className="sparking">
                                <Sparklines data={TwitterChartData} limit={5} width={100} height={20} margin={5}>
                                <SparklinesBars style={{ fill: "black" }}/>
                                </Sparklines>
                                </div>

                                <div className="sparking-twenty" >
                                <p className="twentyhours-featuredcard">24 hours</p> 
                                <p><span className={ OnedaysgrowthTwitter >= 0 ? 'td-priceChange24h-true-featuredcard':'td-priceChange24h-false-featuredcard' }>{ OnedaysgrowthTwitter > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthTwitter}</span> </p>
                               
                                </div>

                        </div>

                        <div className="followers-stats-featured-card">


                                <div className="featured-discord-followers">
                                    <a className="featured-twitter-counts"  href={props.discord}><Icon   icon="ph:discord-logo-fill" style={{ fontSize: '18px' }} />{props.numberofdiscordmembers}</a>
                                </div>

                                <div className="sparking" >
                                
                                <Sparklines data={DiscordChartData} limit={5} width={100} height={20} margin={5}>
                                <SparklinesBars style={{ fill: "black" }}/>
                                </Sparklines>
                                </div>

                                <div className="sparking-twenty" >
                                <p className="twentyhours-featuredcard">24 hours</p> 
                                <p><span className={ OnedaysgrowthDiscord >= 0 ? 'td-priceChange24h-true-featuredcard':'td-priceChange24h-false-featuredcard' }>{ OnedaysgrowthDiscord > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthDiscord}</span> </p>
                               
                                </div>

                                

                        </div>

                       


                    </div>

                
                </div>

            </div>

           


            
            
        </div>


        </div>
    )
}

export default Featuredcard
