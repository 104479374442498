import React from 'react'
import { useState, useEffect } from 'react';
import './Auth.css'
import Login from './Login.js'
import Register from './Register'



function Auth() {

    const [tab, setTab] = useState('login')



    return (
        <div>
            <div className='auth-container'>

            
             <div className='logintitle-container'>
                 <h1>  <span onClick={() => setTab('login')} className={tab === 'login' ? "login-title" : 'register-loginpage'}>Login /</span> <span onClick={() => setTab('register')} className={tab === 'register' ?  "login-title" : 'register-loginpage'}>Register</span></h1>
             </div>
             <div>
                 {tab === 'login' ? <Login/> : <Register/> }
            </div>

            </div>
        </div>
    )
}

export default Auth
