import React from 'react'
import "./Allyouneedtoknow.css"
import findbestnfts from './img/find-best-nfts.png'
import Trend from 'react-trend';
import axios from 'axios';
import { useState, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";


function Allyouneedtoknow() {
    const [number, setNumber] = useState(243)
    const url = 'https://versescore.herokuapp.com/upcoming'

    useEffect(() => {

      
        const fetchData = async () => {
          
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                setNumber(result.data.length)
            
                
              })
              

              
              
            
          }
          

          fetchData();
    }, [])

    const DiscordChartData = [20, 150, 70, 200, 150, 400, 600, 500, 700]
    return (
        <div className="Allyouneedtoknow-container">
            <MediaQuery minWidth={768}>
            <div className='subcontainer-allyouneedtoknow'>

                <div className='allyouneedtoknow-leftpart'>
                        <div className="title-container">
                             <p className="big-title">Where you go to find NFTs</p>
                        </div>
                        <div className="title-container">
                             <p className="subtitle">Upcoming & ongoing collections analyzed by AI.</p>
                             <p className='second-subtitle'>Get key metrics such as price evolution, twitter engagement and discord growth to determine wether or not, a collection worth investing in.</p>
                        </div>

                        <div className='button-container-aynt'>
                            <div className='button-sub'>
                            <button className='register-button'>Register</button>
                            <button className='other-button'><Link className='ayntk-button-link' to="/upcoming" >Upcoming collections</Link></button>
                            </div>
                        </div>
                        <div className='allyouneedtoknow-number-container'>
                        <p className='numberofcollections'>There are currently {number} collections listed.</p>
                        </div>
                   
                </div>

                <div className='allyouneedtoknow-rightpart'>
                <img className='image-allyouneedtoknow' src={findbestnfts}/>
                    
                </div>

            </div>
            </MediaQuery>
            <MediaQuery maxWidth={768}>

                            <div className='subcontainer-allyouneedtoknow-mobile'>

                                <div className='allyouneedtoknow-leftpart'>
                                        <div className="title-container">
                                            <p className="big-title">Where you go to find NFTs</p>
                                        </div>
                                        <div className="title-container">
                                            <p className="subtitle">Upcoming & ongoing collections analyzed by AI.</p>
                                            <p className='second-subtitle'>Get key metrics such as price evolution, twitter engagement and discord growth to determine wether or not, a collection worth investing in.</p>
                                        </div>

                                        <div className='button-container-aynt'>
                                            <div className='button-sub'>
                                            <button className='register-button'>Register</button>
                                            <button className='other-button'><Link className='ayntk-button-link' to="/upcoming" >Upcoming collections</Link></button>
                                            </div>
                                        </div>
                                        <div className='allyouneedtoknow-number-container'>
                                        <p className='numberofcollections'>There are currently {number} collections listed.</p>
                                        </div>
                                
                                </div>

                                

                        </div>

            </MediaQuery>
        </div>
    )
}

export default Allyouneedtoknow
