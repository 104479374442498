import React from 'react'
import "./Nftcard.css"
import TwitterIcon from '@mui/icons-material/Twitter';
import moment from 'moment';
import { Icon } from '@iconify/react';
import DateCountdown from 'react-date-countdown-timer';
import { Sparklines, SparklinesSpots, SparklinesLine, SparklinesBars } from 'react-sparklines';
import { useState, useEffect } from 'react';
import Trend from 'react-trend';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LanguageIcon from '@mui/icons-material/Language';
import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dropscoresvg from "./img/dropscorewhite.png";
import { CheckBoxIcon } from '@mui/icons-material/CheckBox';

import MediaQuery from 'react-responsive';




function Nftcard(props) {

    

    console.log("activeonglet: " + props.activeonglet)

    const refreshfunction = props.activeonglet

    console.log(props.refreshfunction)

    function RefreshPage (activeonglet) {

        console.log('refreshing data...')

        if (activeonglet === "sortbyFeatured") {
            props.sortbyFeatured()
            console.log('')
        }
        else if (activeonglet === "sortbyFeatured") {
            props.sortbyFeatured()
            console.log('')
        }
        else if (activeonglet === "sortbyOutdated") {
            props.sortbyOutdated()
            console.log('')
        }
        else if (activeonglet === "sortbyUpcoming") {
            props.sortbyUpcoming()
            console.log('')
        }
        else if (activeonglet === "sortbyPending") {
            props.sortbyPending()
            console.log('')
        }
        else {
            props.refresh()
        }

    }


    const numberofitem = props.socialmetrics ? props.socialmetrics.length : 0;
    console.log("number of item" + numberofitem)

    

   const twitterfollowersgrowth5 = numberofitem === 0 ? 0 : props.socialmetrics ? props.socialmetrics[numberofitem-1].nbtwt : 0;
   const twitterfollowersgrowth4 = numberofitem <2 ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-2].nbtwt : 0;
   const twitterfollowersgrowth3 = numberofitem <3 ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-3].nbtwt : 0;
   const twitterfollowersgrowth2 = numberofitem <4 ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-4].nbtwt : 0;
   const twitterfollowersgrowth1 = numberofitem <5 ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-5].nbtwt : 0;
   




   const TwitterChartData = [twitterfollowersgrowth2,twitterfollowersgrowth3,twitterfollowersgrowth4,twitterfollowersgrowth5]


   const discordfollowersgrowth5 = numberofitem === 0 ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-1].nbdscrd : 0;
   const discordfollowersgrowth4 = numberofitem <2 ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-2].nbdscrd : 0;
   const discordfollowersgrowth3 = numberofitem <3 ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-3].nbdscrd : 0;
   const discordfollowersgrowth2 = numberofitem <4  ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-4].nbdscrd : 0;
   const discordfollowersgrowth1 = numberofitem <5 ? 0 :props.socialmetrics ? props.socialmetrics[numberofitem-5].nbdscrd : 0;


   const DiscordChartData = [discordfollowersgrowth2,discordfollowersgrowth3,discordfollowersgrowth4,discordfollowersgrowth5]



  const OnedaysgrowthTwitter =  parseInt(twitterfollowersgrowth5) - parseInt(twitterfollowersgrowth4)
   const OnedaysgrowthDiscord =  parseInt(discordfollowersgrowth5) - parseInt(discordfollowersgrowth4)

   const SevendaysgrowthTwitter =  parseInt(twitterfollowersgrowth5) - parseInt(twitterfollowersgrowth1)
   const SevendaysgrowthDiscord =  parseInt(discordfollowersgrowth5) - parseInt(discordfollowersgrowth1)

   console.log(props.maxdropscore )

   const percent =  Math.round(props.dropscore / props.maxdropscore *100)


   const [show, setShow] = useState(false);

   const [edit, setEdit] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [status, setStatus] = useState("Save changes");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Updating...");
    const { name, description, image, website, twitter, discord, mintprice, supply, release } = e.target.elements;
    let details = {
      name: name.value,
      description: description.value,
      image: image.value,
      website: website.value,
      twitter: twitter.value,
      discord: discord.value,
      mintprice: mintprice.value,
      supply: supply.value,
      release: release.value,
    };

    let response = await fetch("https://versescore.herokuapp.com/update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Updated");
    let result = await response.json();
    
    setStatus(result.status + " !");
    RefreshPage(props.activeonglet)
  };


  
   

   const piechartdata = {
    percent: percent, // is require
    colorSlice: '#1bd88f',
    colorCircle: '#393752',
    fontColor: '#1bd88f',
    fontSize: '1.6rem',
    fontWeight: 400,
    size: 30,
    stroke: 10,
    strokeBottom: 5,
    opacity: 10,
    speed: 80,
    round: true,
    number: true,
    
  }


  const piechartdatamodal = {
    percent: percent, // is require
    colorSlice: '#1bd88f',
    colorCircle: '#393752',
    fontColor: '#1bd88f',
    fontSize: '1.6rem',
    fontWeight: 400,
    size: 80,
    stroke: 10,
    strokeBottom: 5,
    opacity: 10,
    speed: 80,
    round: true,
    number: true,
    
  }




  

const ApproveCollection = async () => {
    let NftToApprove = {
    name: props.name
    
  };

    await  fetch('https://versescore.herokuapp.com/approve', {
        method:"POST",
        headers: {
            "x-access-token": localStorage.getItem('token'),
            "content-type": "application/json"
        },
        body: JSON.stringify(NftToApprove),
    })

    RefreshPage(props.activeonglet)
}


const DeleteCollection = async () => {
    const NftToApprove = {"name":props.name}
    console.log(NftToApprove)
 
     await fetch('https://versescore.herokuapp.com/delete', {
         method:"POST",
         headers: {
             "x-access-token": localStorage.getItem('token'),
             "content-type": "application/json"
         },
         body: JSON.stringify(NftToApprove),
     })

     RefreshPage(props.activeonglet)
 }



 const FeatureCollection = async () => {
    const NftToApprove = {"name":props.name}
    console.log(NftToApprove)
 
     await fetch('https://versescore.herokuapp.com/feature', {
         method:"POST",
         headers: {
             "x-access-token": localStorage.getItem('token'),
             "content-type": "application/json"
         },
         body: JSON.stringify(NftToApprove),
     })

     RefreshPage(props.activeonglet)
 }







    return (
        
            <div className='conatiner-card-all'>
                <div>
                
                            <Modal show={show} onHide={handleClose} className="modal-90w" >
                            <MediaQuery minWidth={768}>
                                    <Modal.Header  closeVariant="white">
                                        <p className="close-button" onClick={handleClose}>X</p>
                                    
                                    
                                    </Modal.Header >
                                    
 
                                   
                                    <h1  className="modal-title-props">{props.name}  </h1>




                                        <div className="modal-header">

                                            
                                            <div > 
                                                
                                            
                                                <p className="modal-description">{props.description}</p>


                                                <div className="modal-image-continer">

                                                    <div className="modal-image">
                                                 
                                                
                                                    <img className="projectimage" src={props.image} />

                                                    </div>

                                                

                                                    <div className="modal-dropscore-social">
                                                    
                                                    <h3 className={props.dropscore >=3000 ? 'dropscore': props.dropscore <=3000 & props.dropscore >1000 ? 'dropscore-yellow': 'dropscore-red'}>Dropscore: {props.dropscore}</h3>


                                                            <div className="link-container-modal">
                                                                        <div className="link-items">
                                                                            <a  className="card-links"href={props.website}><LanguageIcon/></a>
                                                                        </div>

                                                                        <div className="link-items">
                                                                            <a  className="card-links" href={'https://twitter.com/' + props.twitter}><TwitterIcon /></a>
                                                                        </div>

                                                                        <div className="link-items">
                                                                            <a className="card-links" href={props.discord}><Icon   icon="ph:discord-logo-fill" style={{ fontSize: '24px' }} /></a>
                                                                        </div>


                                                            </div>

                                                    </div>

                                                </div>

                                                    

                                            </div>
                                            

                                        </div>
                                            
                                
                                            <Modal.Body >

                                                <p className="modal-social-score">Release date: <span className="releasedate-modal">{moment(new Date(props.release)).format("dddd, MMMM Do YYYY")}</span></p>

                                            <div className="modal-socialscore-price">

                                                    <div className="social-score-modal">

                                                    <p className="modal-social-score">Social Score:  </p>
                                                    <CircularProgressBar {...piechartdatamodal}/>

                                                    </div>

                                                    <div className="price-modal">
                                                        <p>Price: {props.mintprice}</p>

                                                    </div>
                                            </div>
                                            
                                            <div className="Twitter-stats-modal">


                                                        <div className="changedata">
                                                            
                                                            <p className="twentyhours">24 hours / 7 days</p>
                                                            <p><span className={ OnedaysgrowthTwitter >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ OnedaysgrowthTwitter > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthTwitter}</span> / <span className={ SevendaysgrowthTwitter >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ SevendaysgrowthTwitter > 0 ? <span>+</span>: <span></span> }{SevendaysgrowthTwitter}</span></p>
                                                            <p><TwitterIcon fontSize="small"  color="primary"/> {props.numberoftwitterfollowers}</p>
                                                        

                                                        </div>

                                        
                                                    

                                                        <div className="chart">
                                                            <Trend autoDraw autoDrawDuration={3000} data={TwitterChartData} gradient={['#2657eb', '#E439CA', '#E439CA']} strokeWidth={5} radius={20} smooth />

                                                        

                                                            
                                                        </div>



                                            </div>



                                            <div className="Twitter-stats-modal">


                                                        <div className="changedata">
                                                            <p className="twentyhours">24 hours / 7 days</p> 
                                                            <p><span className={ OnedaysgrowthDiscord >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ OnedaysgrowthDiscord > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthDiscord}</span> / <span className={ SevendaysgrowthDiscord >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ SevendaysgrowthDiscord > 0 ? <span>+</span>: <span></span> }{SevendaysgrowthDiscord}</span></p>
                                                            <p><Icon icon="logos:discord-icon" />  {props.numberofdiscordmembers}</p>
                                                        </div>
                                                            
                                                            
                                                
                                                            

                                                            <div className="chart">

                                                            


                                                            <Trend autoDraw autoDrawDuration={3000} data={DiscordChartData} gradient={['#2657eb', '#E439CA', '#E439CA']} strokeWidth={5} radius={20} smooth />

                                                         
                                                            </div>


                                            </div>
                                            
                                            
                                            
                                            
                                            </Modal.Body>
                                            </MediaQuery>
                        </Modal>
                        

                        {props.isloggedin ? <button onClick={ApproveCollection}className="approve-button">{props.isPublished ?  "Published ✅" : "+ Approve"} </button> : null}
                        {props.isloggedin ? <button onClick={FeatureCollection}className="approve-button">Featured {props.isFeatured ? "✅" : "❌"}</button> : null}
                        {props.isloggedin ? <button onClick={DeleteCollection} className="approve-button">Delete 🗑️</button> : null}
                        {props.isloggedin ? <button onClick={setEdit} className="approve-button">Edit</button> : null }


                        {edit ? <div  className="edit-box">
                            <h3>Edit collection</h3> 
                            <button className="close-button-edit-box" onClick={() => setEdit(false)}>X</button>


                           
                           
                            
                            <div className='edit-box-container'>
                                    <form onSubmit={handleSubmit}>
                                        <div className="edit-box-label-container">
                                            <label className="label" htmlFor="name" ><span className="edit-box-label-title">Name: </span></label>
                                            <input className='edit-box-input-form' type="text" id="name" defaultValue={props.name} />
                                        </div>

                                        <div className="edit-box-label-container">
                                            <label className="label" htmlFor="name" ><span className="edit-box-label-title">Description: </span></label>
                                            <input className='edit-box-input-form' type="text" id="description" defaultValue={props.description} />
                                        </div>


                                        <div className="edit-box-label-container">
                                            <label className="label" htmlFor="name" ><span className="edit-box-label-title">Picture link: </span></label>
                                            <input className='edit-box-input-form' type="text" id="image" defaultValue={props.image} />
                                        </div>


                                        <div className="edit-box-label-container">
                                            <label className="label" htmlFor="name" ><span className="edit-box-label-title">Website: </span></label>
                                            <input className='edit-box-input-form' type="text" id="website" defaultValue={props.website} />
                                        </div>


                                        <div className="edit-box-label-container">
                                            <label className="label" htmlFor="name" ><span className="edit-box-label-title">Twitter: </span></label>
                                            <input className='edit-box-input-form' type="text" id="twitter" defaultValue={props.twitter} placeholder="Handle only, Ex: Dropscore_io"/>
                                        </div>


                                        <div className="edit-box-label-container">
                                            <label className="label" htmlFor="name" ><span className="edit-box-label-title">Discord: </span></label>
                                            <input className='edit-box-input-form' type="text" id="discord" defaultValue={props.discord} />
                                        </div>


                                        <div className="edit-box-label-container">
                                            <label className="label" htmlFor="name" ><span className="edit-box-label-title">Price: </span></label>
                                            <input className='edit-box-input-form' type="text" id="mintprice" defaultValue={props.mintprice} placeholder="Ex: 1 SOL"/>
                                        </div>

                                        <div className="edit-box-label-container">
                                            <label className="label" htmlFor="name" ><span className="edit-box-label-title">Supply: </span></label>
                                            <input className='edit-box-input-form' type="text" id="supply" defaultValue={props.supply} placeholder="Ex: 5999"/>
                                        </div>

                                        <div className="edit-box-label-container">
                                            <label className="label" htmlFor="name" ><span className="edit-box-label-title">Mint date: </span></label>
                                            <input className='edit-box-input-form' type="text" id="release" defaultValue={moment(new Date(props.release)).format("MM/DD/YYYY")} placeholder="ex: 11/30/2021"/>
                                        </div>
                                        


                                        


                                        <div className="edit-box-button-container">
                                        <button  className="fetch-button-edit-box" type="submit">{status}</button>
                                        </div>
                                        
                                    </form>
                            </div>
                            
                            
                            </div> : null}


                        

                </div>
                
            
            <div className="card"  onClick={handleShow}>

                
               <div className="titleimage-container">

                            <div className="image-container-admin">
                            <h1 className="project-title">{props.name} <span className='isPending'>{props.isPublished === false ? <p>Waiting for approval</p> : null }</span></h1>
                                    
                                    <img className="projectimage" src={props.image} />
                                   
                            </div>

                            <div className="card-title-score-container">
                                    
                                    
                                   
                                       
                                    <h3 className={props.dropscore >=3000 ? 'dropscore': props.dropscore <=3000 & props.dropscore >1000 ? 'dropscore-yellow': 'dropscore-red'}>Dropscore: {props.dropscore}</h3>

                            </div>

                           

                           


                </div>



                



                    <div className="data-container">

                           

                            
                            <div className="info-container">


                               


                            <div className="social-analytics">
                            

                                <div className="Twitter-stats">


                                

                                    
                                            <div className="changedata">
                                                
                                                <p className="twentyhours">24 hours / 7 days</p>
                                                <p><span className={ OnedaysgrowthTwitter >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ OnedaysgrowthTwitter > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthTwitter}</span> / <span className={ SevendaysgrowthTwitter >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ SevendaysgrowthTwitter > 0 ? <span>+</span>: <span></span> }{SevendaysgrowthTwitter}</span></p>
                                                <p><TwitterIcon fontSize="small"  color="primary"/> {props.numberoftwitterfollowers}</p>
                                            

                                            </div>

                               
                                        

                                            <div className="chart">
                                                <Trend autoDraw autoDrawDuration={3000} data={TwitterChartData} gradient={['#2657eb', '#E439CA', '#E439CA']} strokeWidth={5} radius={20} smooth />

                                              

                                                
                                            </div>



                                </div>


                                


                            </div>


                                

                                    
                            



                            
                
                        </div>
                
                    </div>

                


            </div>
            </div>
            

            
        
    )
}

export default Nftcard
