import React from 'react'
import './Seeallbutton.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

function Seeallbutton() {
    return (
        <div>
            <div className='button-contaienr'>
            <button className='buttoh'><Link className="menu-text" to="/upcoming"  >See all upcoming collections</Link></button>
            </div>
        </div>
    )
}

export default Seeallbutton
