import React from 'react'
import Trend from 'react-trend';
import './Loading.css'

function Loading() {
    const DiscordChartData = [20, 150, 70, 200, 150, 400, 600, 500, 700]

    return (
        <div className='container-loading'>
            <p className='loading-text'>Loading...</p>
            <Trend autoDraw autoDrawDuration={2000} data={DiscordChartData} color='black' strokeWidth={3} radius={0} smooth />
                   
        </div>
    )
}

export default Loading
