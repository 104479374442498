import logo from './logo.svg';
import './App.css';
import Topbar from './Topbar';
import Allyouneedtoknow from "./Allyouneedtoknow"
import Trend from "./Trend"
import Bestperforming from './Bestperforming';
import Footer from './Footer'
import Ethstats from "./Ethstats"
import Hype from './Hype'
import Featured from './Featured'
import { useState, useEffect } from 'react';
import Lottie from "react-lottie";
import * as location from './img/loading.json'
import Upcoming from './Upcoming';
import Seeallbutton from './Seeallbutton.js'

function App() {
  document.title = 'VerseScore.com';

  const [loading, setloading] = useState(undefined);


 
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: location.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  
  
  

  return (

    <>
      {loading ? (
        <div className="loading-container">
        <Lottie options={defaultOptions1} height={100} width={100} />
        </div>
      ) : (
        
      


    <div className="App">
      <header className="App-header">
       
      </header>



      <body className='App-body'>
       
        <Allyouneedtoknow />
        <Featured/>
        
       
        <Upcoming numberofrows={6}/>
        
       
        <Seeallbutton/>



      </body>

      
    </div>
 )}
 </>
);
}
export default App;
