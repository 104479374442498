import React from 'react'
import "./Admin.css"
import axios from 'axios';
import { useState, useEffect } from 'react';
import Nftcard from "./Nftcard.js"
import moment from 'moment';
import Lottie from "react-lottie";
import * as location from './img/loading.json'
import { useHistory } from 'react-router'
import Featuredcard from "./Featuredcard"

function Admin() {

    const history = useHistory()

    const [user, setUser] = useState(undefined);

    async function Logout () {
        localStorage.removeItem("token")
        console.log(localStorage)
        await history.push ('/login')
    }


   
    const [query, setQuery] = useState("")
    


    useEffect(() => {
        fetch('https://versescore.herokuapp.com/isAuth', {
            method:"GET",
            headers: {
                "x-access-token": localStorage.getItem('token')
            }
        })
        .then(res => res.json())
        .then(data => data.isLoggedIn === false ? history.push ('/login'): setUser(data.username))
    }, [Logout])

    const defaultOptions1 = {
        loop: true,
        autoplay: true,
        animationData: location.default,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

    const urlpending = 'https://versescore.herokuapp.com/pending'
    const urlupcoming = 'https://versescore.herokuapp.com/upcoming'
    const urloutdated = 'https://versescore.herokuapp.com/outdated'
    const url = 'https://versescore.herokuapp.com/allupcoming'
    const urlfeatured ='https://versescore.herokuapp.com/getfeatured'
    
    const [nfts, setNfts] = useState([""]);
    const [maxdropscore, setMaxdropscore] = useState([""]);
    const [loading, setloading] = useState(undefined);
    const [activeonglet, setActiveonglet] = useState('')


   const refreshData = async () => {
        setloading(true)
        setActiveonglet("refreshData")
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                setNfts(result.data)
                setloading(false)
                
              })
    }

    const sortbyPending = async () => {

        setloading(true)
        setActiveonglet("sortbyPending")
            fetch(urlpending)
              .then((response) => response.json())
              .then((result) => {
                setNfts(result.data)
                setloading(false)
                
              })
    }


    const sortbyFeatured = async () => {
      setloading(true)
      setActiveonglet("sortbyFeatured")
          fetch(urlfeatured)
            .then((response) => response.json())
            .then((result) => {
              setNfts(result.data)
              setloading(false)
              
            })
  }

    

    const sortbyUpcoming = async () => {
        setloading(true)
        setActiveonglet("sortbyUpcoming")
            fetch(urlupcoming)
              .then((response) => response.json())
              .then((result) => {
                setNfts(result.data)
                setloading(false)
                
              })
    }

    const sortbyOutdated = async () => {
        setloading(true)
        setActiveonglet("sortbyOutdated")
            fetch(urloutdated)
              .then((response) => response.json())
              .then((result) => {
                setNfts(result.data)
                setloading(false)
                
              })
    }

    useEffect(() => {   
        const fetchData = async () => {
          setloading(true)
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                setNfts(result.data)
                setloading(false)
                
              }) 
          }
          fetchData();
    }, [])


    return (
        <>
      {loading ? (
        <div className="loading-container">
        <Lottie className="lottieanimation" options={defaultOptions1} height={100} width={100} />
        </div>
      ) : (
        <div className="upcoming-container-admin">

            

      <div className="ranking-header">
        
              <div className="ranking-header">
                  <h1>Admin Panel. Hello {user} 👋</h1>
                  
                  <p>Approve, update, feature & delete collections.</p>
                  <button className="logout-button" onClick={Logout}>Logout</button>

                  
              </div>

              
              <div className='filter-container'>

                  <div className='searchbar-container'>
                      <input className="input" placeholder=" 🔍 Search for a collection" onChange={event => setQuery(event.target.value)}/>
                  </div>

                  <div className="filter-button-container">
                      <button className='fetch-button' onClick={refreshData}>All</button>
                      <button className='fetch-button' onClick={sortbyFeatured}>Featured collections</button>
                      <button  className='fetch-button'onClick={sortbyPending}>Waiting for approval</button>
                      <button className='fetch-button' onClick={sortbyOutdated}>Outdated</button>
                      <button className='fetch-button' onClick={sortbyUpcoming}>Upcoming</button>
                      
                  </div>

              

              </div>

        </div>



        <div className="nftcarcontainer">

        {
            nfts.filter(nft => {
                if (query === '') {
                return nft;
                } else if (nft.name.toLowerCase().includes(query.toLowerCase())) {
                return nft;
                }
            }).map(nft => <Nftcard name={nft.name} description={nft.description} release={nft.release} mintprice={nft.mintprice} image={nft.image} website={nft.website} twitter={nft.twitter} discord={nft.discord} numberoftwitterfollowers={nft.numberoftwitterfollowers} numberofdiscordmembers={nft.numberofdiscordmembers} dropscore={nft.dropscore} twitterfollowersgrowth={nft.followersgrowth}  socialmetrics={nft.followersgrowth} tweetsmetrics={nft.tweets ? nft.tweets : 0}  maxdropscore={maxdropscore} socialmetrics={nft.followersgrowth} isloggedin={true} isPublished={nft.isPublished} isFeatured={nft.isFeatured} refresh={refreshData} supply={nft.supply} activeonglet={activeonglet} sortbyOutdated={sortbyOutdated} sortbyUpcoming={sortbyUpcoming} sortbyFeatured={sortbyFeatured}  sortbyPending={sortbyPending} refreshData={refreshData} blockchain={nft.blockchain}/>)
        }       

            
            

        </div>



          

    </div>
      )}
   



      
 
    
    </>
    )
}

export default Admin
