import React from 'react'
import './Featured.css'
import Featuredcard from "./Featuredcard"
import { useState, useEffect } from 'react';
import moment from 'moment';

function Featured() {

    const url = 'https://versescore.herokuapp.com/getfeatured'
    const [nfts, setNfts] = useState([""]);
    const [maxdropscore, setMaxdropscore] = useState([""]);
    


    const fetchmaxdropscore = async () => {
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          setMaxdropscore(result.maxdropscore)
        })
        
    }

    useEffect(() => {

        const fetchData = async () => {
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                setNfts(result.data)
              })
          }
       

          fetchData();
          fetchmaxdropscore();
    }, [])

    return (
        <div className="featured-main-container">
          
          <div className="title-featured-container">
              <h1>Featured Collections</h1>
              <p>Collections selected by the team</p>
          </div>

          <div className="featured-container">
              {nfts.slice(0, 3).map(nft => <Featuredcard name={nft.name} description={nft.description} release={moment(new Date(nft.release)).format(" MMMM Do YYYY")} mintprice={nft.mintprice} image={nft.image} website={nft.website} twitter={nft.twitter} discord={nft.discord} numberoftwitterfollowers={nft.numberoftwitterfollowers} numberofdiscordmembers={nft.numberofdiscordmembers} dropscore={nft.dropscore} tweetsmetrics = {nft.tweets} socialmetrics={nft.followersgrowth} isFeatured={nft.isFeatured} maxdropscore={maxdropscore} blockchain={nft.blockchain}/>)}    
          </div>

        </div>
    )
}

export default Featured
