import React from 'react'
import { useHistory } from 'react-router'
import { useEffect } from 'react'
import './Login.css'
import MediaQuery from 'react-responsive';


function Register() {

    const history = useHistory()

    async function handleLogin(e) {
        e.preventDefault()

        const form = e.target;
        const user =  {
            
            username: form[0].value,
            password: form[1].value
        }

        await fetch('https://versescore.herokuapp.com/register', {
            method:"POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(user)
        })
        .then(res => res.json())
        .then(data => {
            localStorage.setItem("token", data.token)
            console.log(localStorage)
        })

        await history.push ('/admin')
    }

    useEffect(() => {
        fetch('https://versescore.herokuapp.com/isAuth', {
            method:"GET",
            headers: {
                "x-access-token": localStorage.getItem('token')
            }
        })
        .then(res => res.json())
        .then(data => data.isLoggedIn ? history.push ('/admin'): null)
    }, [])



    return (

        
        <div className="login-container">

        <MediaQuery minWidth={768}>

            <div className="form">
           

            <form onSubmit={event => handleLogin(event)}>
                <div>
                <input required type="email" placeholder="email"/>
                </div>
                <div>
                <input required type="username" placeholder="username"/>
                </div>
                <div>
                <input required type="password" placeholder="password"/>
                </div>
                <div >
                <input className="button" type="submit" value="Register"/>
                </div>

            </form>

            </div>

        </MediaQuery>

        <MediaQuery maxWidth={768}>


        <div className="form-login-mobile ">
            

            <form onSubmit={event => handleLogin(event)}>
                <div>
                <input required type="email" placeholder="email"/>
                </div>
                <div>
                <input required type="username" placeholder="username"/>
                </div>
                <div>
                <input required type="password" placeholder="password"/>
                </div>
                <div >
                <input className="button" type="submit" value="Register"/>
                </div>

            </form>

            </div>



        </MediaQuery>
            
        </div>
    )
}

export default Register

